<template>
  <NuxtLink
    :to="ctaRoute"
    class="flex items-center justify-between"
    :class="{
      'cursor-pointer hover:border-gray-800': ctaRoute,
      'w-full flex-row justify-start bg-[#F2F2F280] px-4 py-2 md:flex-row': isDepartmentCard,
      'w-full flex-col justify-start px-6 py-2 md:justify-center md:px-8 lg:px-10': !isDepartmentCard,
    }"
    :target="target">
    <div
      class="flex items-center"
      :class="{
        'flex-col': !isDepartmentCard,
      }">
      <div
        v-if="data.image && !isInsuranceCard"
        class="relative"
        :class="{ 'mr-2': isDepartmentCard, 'px-6': !isDepartmentCard }">
        <ImageStrapiImage
          class="z-10 object-cover"
          :class="{
            'max-h-14 max-w-14 md:max-h-20 md:max-w-20': isDepartmentCard,
            'h-30 md:min-w-[80px]': !isDepartmentCard,
          }"
          :data="data.image.data" />
        <span
          v-if="isPromoted"
          class="absolute bottom-0 left-1/2 ml-auto -translate-x-2/4 rounded-lg border-2 border-gray-50 bg-yellow p-3 py-0.5 text-sm font-medium text-gray-900"
          >Neu</span
        >
      </div>
      <h2
        v-if="data.title"
        class="text-2xl text-gray-900"
        :class="{
          'text-center': !isDepartmentCard,
          'text-start': isInsuranceCard,
          'justify-self-start text-left text-base font-normal leading-tight': isDepartmentCard,
          'overflow-hidden text-ellipsis': isPromoted,
        }">
        {{ props.data.title }}
      </h2>
      <div
        v-if="data.text"
        :class="{
          'text-start text-base text-gray-900 md:text-lg': isInsuranceCard,
          hidden: isDepartmentCard,
        }"
        v-html="data.text" />

      <div
        v-if="data?.ctaText"
        class="mt-4 flex items-center text-gray-900"
        :class="{
          hidden: isDepartmentCard,
          'justify-center text-center': !isInsuranceCard,
        }">
        <p class="mr-2">
          {{ data.ctaText }}
        </p>
        <SvgoArrowRight class="text-yellow-900" />
      </div>
    </div>

    <div v-if="isDepartmentCard" class="justify-self-end">
      <SvgoChevronRight class="text-xl text-gray-500" />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { withLeadingSlash } from "ufo";
import type { Department } from "~/types/strapi/api/department/content-types/department/department";
import type { Card } from "~/types/strapi/components/shared/interfaces/Card";

const props = defineProps<{
  data: Department["attributes"] | Card;
  isDepartmentCard?: boolean;
  isInsuranceCard?: boolean;
}>();

const ctaRoute = computed(() => {
  const { href, slug } = props.data;

  if (href !== undefined) {
    return isExternalLink(href) ? href : withLeadingSlash(href);
  }
  return withLeadingSlash(slug);
});

const isPromoted = computed(() => {
  if (props.isDepartmentCard) {
    return isPromotedDepartment(props.data.title);
  }

  return false;
});

const target = computed(() => {
  if (isExternalLink(ctaRoute.value)) {
    return "_blank";
  }

  return "_self";
});
</script>

<style lang="scss" scoped></style>
